@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';


.App {
  background-image: full-screen;
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/** random task changes start **/
.pointer,
.logo {
  cursor: pointer;
}

.live-play-button-container .play {
  bottom: 30%;
  height: 40%;
  left: 30%;
  position: absolute;
  width: 40%;
  opacity: 0;
  -webkit-transition: opacity .3s;
  transition: opacity .3s;
  border: 1.5px solid rgb(215, 215, 215);
  border-radius: 100%;
}

.SVGInline {
  inset: 0px 56% 0px 0%;
  display: block;
  margin: 0px;
  padding: 0px;
  position: absolute;
}

.play svg {
  fill: rgb(255, 255, 255);
}

.play svg {
  height: 100%;
  position: absolute;
  width: 100%;
  left: 74%;
}

.live-group-item:hover .play,
.live-group-item:hover .live-play-button-container {
  opacity: 1;
}

.live-group-item {
  display: block;
  position: relative;
}

.live-play-button-container {
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #00000073;
  opacity: 0;
}

.play:hover {
  border: 1px solid #9d9c9c;
}

.podcast-image {
  width: 170px;
  position: absolute;
  right: 136px;
  margin-top: 41px;
}

@media screen and (max-width: 1024px) and (min-width: 768px) {
  .podcast-image {
    width: 104px;
    right: 84px;
    margin-top: 11px;
    position: absolute;
  }
}

@media screen and (max-width: 768px) and (min-width: 420px) {
  .podcast-image {
    width: 89px;
    right: 67px;
    margin-top: 2px;
    position: absolute;
  }
}

@media screen and (max-width: 420px) {
  .podcast-image {
    width: 100%;
    right: 0;
    margin-top: 0;
    position: relative;
  }
  .podcast-play-container {
    padding: 0px 6px!important;
}
}


.bg-gray-75 {
  background: #80808029;
}

.global-player {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 9;
}

.global-player-thumbnail {
  width: 59px;
  border: 1px solid #a9a9a942;
  background-color: gray;
}
.global-player-title {
  font-size: 11px;
  margin: 4px;
}

/** random task changes start end**/